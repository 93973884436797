<template>
  <v-app>

    <v-app-bar v-if="screen > 800 && (pathname != '/sp/clientes/datos' && pathname != '/sp/clientes/resumen' && pathname != '/sp/clientes/finish')" :class="navVar" class="appBarStyle">

      <v-spacer></v-spacer>

    </v-app-bar>
    
    <v-app-bar v-if="screen > 800 && (pathname == '/sp/clientes/datos' || pathname == '/sp/clientes/resumen' || pathname == '/sp/clientes/finish')" :class="navVar" class="appBarStyle3">

      <v-spacer></v-spacer>

    </v-app-bar>
  

    <v-app-bar v-if="screen <= 800" :class="navVar" style="height: 46.5vw ;" class="appBarStyle2" >
      
      <v-spacer></v-spacer>

    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <Tarjetas />
    <Footer />
  
  </v-app>
</template>

<script>
import Footer from "@/components/Footer.vue";
import MovilClientes from "@/assets/images/MovilClientes.jpg";
import MovilGeneral from "@/assets/images/MovilGeneral.jpg";
import MovilColaborador from "@/assets/images/MovilColaborador.jpg";
import Tarjetas from "@/components/Tarjetas.vue";
import path from "path";

export default {
  components: {
    Footer,
    Tarjetas,

  },
  data: () => ({
    MovilClientes,
    MovilGeneral,
    MovilColaborador,
    screen: window.screen.width,
    navVar: '',
    pathname: window.location.pathname,
    camapana: '',

  }),
  mounted() {
    if (this.screen > 800) {
      switch (this.pathname) {
        case '/sp/clientes/inicio':
          this.navVar = 'navVarConsultaFinish'
          break;

        case '/sp/clientes/datos':
          this.navVar = 'navVarConsultaFinish'
          break;

        case '/sp/clientes/resumen':
          this.navVar = 'navVarConsultaFinish'
          break;
        
        case '/sp/clientes/finish':
          this.navVar = 'navVarConsultaFinish'
          break;

        case '/sp/general/inicio':
          this.navVar = 'navVarConsultaFinish'
          break;

        case '/sp/general/datos':
          this.navVar = 'navVarConsultaFinish'
          break;

        case '/sp/general/resumen':
          this.navVar = 'navVarConsultaFinish'
          break;

        case '/sp/colaboradores/inicio':
          this.navVar = 'navVarConsultaFinish'
          break;

        case '/sp/colaboradores/datos':
          this.navVar = 'navVarConsultaFinish'
          break;

        case '/sp/colaboradores/resumen':
          this.navVar = 'navVarConsultaFinish'
          break;

        case '/sp/colaboradores/consulta':
        this.navVar = 'navVarConsultaFinish'
        break;

        case '/sp/colaboradores/finish':
          this.navVar = 'navVarConsultaFinish'
          break;

        case '/sp/consulta':
          this.navVar = 'navVarConsultaFinish'
          break;

        default:
          this.navVar = 'navVarConsultaFinish2'
          break;
      }
    } else if (this.screen < 800) {
      switch (this.pathname) {

        case '/sp/clientes/inicio':
          this.navVar = 'navVarConsultaFinishMovil'
          break;

        case '/sp/clientes/datos':
          this.navVar = 'navVarConsultaFinishMovil'
          break;

        case '/sp/clientes/resumen':
          this.navVar = 'navVarConsultaFinishMovil'
          break;
          
        case '/sp/clientes/finish':
          this.navVar = 'navVarConsultaFinishMovil'
          break;

        case '/sp/general/inicio':
          this.navVar = 'navVarConsultaFinishMovil'
          break;

        case '/sp/general/datos':
          this.navVar = 'navVarConsultaFinishMovil'
          break;

        case '/sp/general/resumen':
          this.navVar = 'navVarConsultaFinishMovil'
          break;

        case '/sp/colaboradores/inicio':
          this.navVar = 'navVarConsultaFinishMovil'
          break;

        case '/sp/colaboradores/datos':
          this.navVar = 'navVarConsultaFinishMovil'
          break;

        case '/sp/colaboradores/resumen':
          this.navVar = 'navVarConsultaFinishMovil'
          break;

        case '/sp/consulta':
          this.navVar = 'navVarConsultaFinishMovil'
          break; 

        case '/sp/finish':
        this.navVar = 'navVarConsultaFinishMovil'
        break; 

        default:
          this.navVar = 'navVarColaboradorMovil2'
          break;

      }
    }
  }
};
</script>

<style>
.appBarStyle {
  height: 310px !important; 
  box-shadow: 0px 0px 0px  !important;
  background-color: #F3F3F3 !important;
}
.appBarStyle2 {
  height: 200px !important; 
  box-shadow: 0px 0px 0px  !important;
  background-color: #F3F3F3 !important;
}

.appBarStyle3 {
  height: 310px !important; 
  box-shadow: 0px 0px 0px  !important;
  background-color: #FFFFFF !important;
}

.navVarClientes {
  background-image: url("~@/assets/images/banner2.png");
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.navVarGeneral {
  background-image: url("~@/assets/images/WebGeneral.jpg");
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  
}

.navVarColaborador {
  background-image: url("~@/assets/images/WebColaborador.jpg");
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.navVarConsultaFinish {
  background-image: url("~@/assets/images/southbridge-desktop.png");
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.navVarConsultaFinish2 {
  background-image: url("~@/assets/images/Banner.png");
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.navVarClientesMovil {
  background-image: url("~@/assets/images/southbridge-mobile.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  
}

.navVarGeneralMovil {
  background-image: url("~@/assets/images/southbridge-mobile.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  
}

.navVarColaboradorMovil {
  background-image: url("~@/assets/images/southbridge-mobile.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  
}

.navVarColaboradorMovil2 {
  background-image: url("~@/assets/images/bannerMovil.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  
}

.navVarConsultaFinishMovil {
  background-image: url("~@/assets/images/southbridge-mobile.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  
}

.movil {
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  padding: 0px 0px ;
  width: 100%;
  height: 120%;
}




@font-face {
  font-family: "Scotia_Bd";
  src: local("Scotia_Bd"),
    url(/fonts/Scotia_Bd.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_BdIt";
  src: local("Scotia_BdIt"),
    url(/fonts/Scotia_BdIt.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_Headline";
  src: local("Scotia_Headline"),
    url(/fonts/Scotia_Headline.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_It";
  src: local("Scotia_It"),
    url(/fonts/Scotia_It.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_Legal";
  src: local("Scotia_Legal"),
    url(/fonts/Scotia_Legal.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_Lt";
  src: local("Scotia_Lt"),
    url(/fonts/Scotia_Lt.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_LtIt";
  src: local("Scotia_LtIt"),
    url(/fonts/Scotia_LtIt.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_Rg";
  src: local("Scotia_Rg"),
    url(/fonts/Scotia_Rg.otf) format("truetype");
}

html body div.v-application--wrap{
  margin-bottom: -6%;
}
</style>